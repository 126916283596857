!function () {
  function e(e, a) {
    return e.section(a.get(["items"], !1), a, {
      block: t
    }, null).write(" ");
  }
  function t(e, t) {
    return e.section(t.get(["searchTerms"], !1), t, {
      block: a
    }, null).section(t.get(["navigations"], !1), t, {
      block: n
    }, null);
  }
  function a(e, t) {
    return e.write('<li class="sayt-autocomplete-listitem" data-ui-autocomplete-item=\'{"value":"').reference(t.get(["value"], !1), t, "h").write('", "type":"searchTerm"}\'><a class="sayt-autocomplete"><span class="sayt-content">').reference(t.get(["value"], !1), t, "h").write('</span><span class="sayt-additional"></span></a></li>');
  }
  function n(e, t) {
    return e.section(t.get(["values"], !1), t, {
      block: i
    }, null);
  }
  function i(e, t) {
    return e.write('<li class="sayt-autocomplete-navigation" data-ui-autocomplete-item=\'{"value":"').reference(t.getPath(!0, []), t, "h").write('", "type":"navigation", "category":"').reference(t.get(["name"], !1), t, "h").write('"}\'><a class="sayt-navigation"><span class="sayt-content">').reference(t.getPath(!0, []), t, "h").write("</span></a></li>");
  }
  return dust.register("autocompleteTemplate", e), e;
}();