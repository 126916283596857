function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/* Build includes: Flexbox, Flexbox(legacy) Flexbox(tweener), matchMedia*/
/*! modernizr 3.5.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-flexbox-flexboxlegacy-flexboxtweener-matchmedia-setclasses !*/
!function (e, n, t) {
  function r(e, n) {
    return _typeof(e) === n;
  }
  function o() {
    var e, n, t, o, i, s, a;
    for (var l in C) if (C.hasOwnProperty(l)) {
      if (e = [], n = C[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
      for (o = r(n.fn, "function") ? n.fn() : n.fn, i = 0; i < e.length; i++) s = e[i], a = s.split("."), 1 === a.length ? Modernizr[a[0]] = o : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = o), x.push((o ? "" : "no-") + a.join("-"));
    }
  }
  function i(e) {
    var n = w.className,
      t = Modernizr._config.classPrefix || "";
    if (_ && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2");
    }
    Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), _ ? w.className.baseVal = n : w.className = n);
  }
  function s(e, n) {
    return !!~("" + e).indexOf(n);
  }
  function a() {
    return "function" != typeof n.createElement ? n.createElement(arguments[0]) : _ ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments);
  }
  function l(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
      return n + t.toUpperCase();
    }).replace(/^-/, "");
  }
  function f(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function u(e, n, t) {
    var o;
    for (var i in e) if (e[i] in n) return t === !1 ? e[i] : (o = n[e[i]], r(o, "function") ? f(o, t || n) : o);
    return !1;
  }
  function d(e) {
    return e.replace(/([A-Z])/g, function (e, n) {
      return "-" + n.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }
  function p(n, t, r) {
    var o;
    if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, n, t);
      var i = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));else if (i) {
        var s = i.error ? "error" : "log";
        i[s].call(i, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];
    return o;
  }
  function c() {
    var e = n.body;
    return e || (e = a(_ ? "svg" : "body"), e.fake = !0), e;
  }
  function m(e, t, r, o) {
    var i,
      s,
      l,
      f,
      u = "modernizr",
      d = a("div"),
      p = c();
    if (parseInt(r, 10)) for (; r--;) l = a("div"), l.id = o ? o[r] : u + (r + 1), d.appendChild(l);
    return i = a("style"), i.type = "text/css", i.id = "s" + u, (p.fake ? p : d).appendChild(i), p.appendChild(d), i.styleSheet ? i.styleSheet.cssText = e : i.appendChild(n.createTextNode(e)), d.id = u, p.fake && (p.style.background = "", p.style.overflow = "hidden", f = w.style.overflow, w.style.overflow = "hidden", w.appendChild(p)), s = t(d, e), p.fake ? (p.parentNode.removeChild(p), w.style.overflow = f, w.offsetHeight) : d.parentNode.removeChild(d), !!s;
  }
  function v(n, r) {
    var o = n.length;
    if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;) if (e.CSS.supports(d(n[o]), r)) return !0;
      return !1;
    }
    if ("CSSSupportsRule" in e) {
      for (var i = []; o--;) i.push("(" + d(n[o]) + ":" + r + ")");
      return i = i.join(" or "), m("@supports (" + i + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == p(e, null, "position");
      });
    }
    return t;
  }
  function y(e, n, o, i) {
    function f() {
      d && (delete z.style, delete z.modElem);
    }
    if (i = r(i, "undefined") ? !1 : i, !r(o, "undefined")) {
      var u = v(e, o);
      if (!r(u, "undefined")) return u;
    }
    for (var d, p, c, m, y, g = ["modernizr", "tspan", "samp"]; !z.style && g.length;) d = !0, z.modElem = a(g.shift()), z.style = z.modElem.style;
    for (c = e.length, p = 0; c > p; p++) if (m = e[p], y = z.style[m], s(m, "-") && (m = l(m)), z.style[m] !== t) {
      if (i || r(o, "undefined")) return f(), "pfx" == n ? m : !0;
      try {
        z.style[m] = o;
      } catch (h) {}
      if (z.style[m] != y) return f(), "pfx" == n ? m : !0;
    }
    return f(), !1;
  }
  function g(e, n, t, o, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + " " + E.join(s + " ") + s).split(" ");
    return r(n, "string") || r(n, "undefined") ? y(a, n, o, i) : (a = (e + " " + P.join(s + " ") + s).split(" "), u(a, n, t));
  }
  function h(e, n, r) {
    return g(e, t, t, n, r);
  }
  var x = [],
    C = [],
    S = {
      _version: "3.5.0",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function on(e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function addTest(e, n, t) {
        C.push({
          name: e,
          fn: n,
          options: t
        });
      },
      addAsyncTest: function addAsyncTest(e) {
        C.push({
          name: null,
          fn: e
        });
      }
    },
    Modernizr = function Modernizr() {};
  Modernizr.prototype = S, Modernizr = new Modernizr();
  var w = n.documentElement,
    _ = "svg" === w.nodeName.toLowerCase(),
    b = "Moz O ms Webkit",
    E = S._config.usePrefixes ? b.split(" ") : [];
  S._cssomPrefixes = E;
  var P = S._config.usePrefixes ? b.toLowerCase().split(" ") : [];
  S._domPrefixes = P;
  var T = {
    elem: a("modernizr")
  };
  Modernizr._q.push(function () {
    delete T.elem;
  });
  var z = {
    style: T.elem.style
  };
  Modernizr._q.unshift(function () {
    delete z.style;
  }), S.testAllProps = g, S.testAllProps = h, Modernizr.addTest("flexbox", h("flexBasis", "1px", !0)), Modernizr.addTest("flexboxlegacy", h("boxDirection", "reverse", !0)), Modernizr.addTest("flexboxtweener", h("flexAlign", "end", !0));
  var N = function N(n) {
    var r,
      o = prefixes.length,
      i = e.CSSRule;
    if ("undefined" == typeof i) return t;
    if (!n) return !1;
    if (n = n.replace(/^@/, ""), r = n.replace(/-/g, "_").toUpperCase() + "_RULE", r in i) return "@" + n;
    for (var s = 0; o > s; s++) {
      var a = prefixes[s],
        l = a.toUpperCase() + "_" + r;
      if (l in i) return "@-" + a.toLowerCase() + "-" + n;
    }
    return !1;
  };
  S.atRule = N;
  var j = S.prefixed = function (e, n, t) {
    return 0 === e.indexOf("@") ? N(e) : (-1 != e.indexOf("-") && (e = l(e)), n ? g(e, n, t) : g(e, "pfx"));
  };
  Modernizr.addTest("matchmedia", !!j("matchMedia", e)), o(), i(x), delete S.addTest, delete S.addAsyncTest;
  for (var A = 0; A < Modernizr._q.length; A++) Modernizr._q[A]();
  e.Modernizr = Modernizr;
}(window, document);