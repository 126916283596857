!function () {
  function e(e, r) {
    return e.section(r.get(["items"], !1), r, {
      block: t
    }, null);
  }
  function t(e, t) {
    return e.section(t.get(["products"], !1), t, {
      block: r
    }, null);
  }
  function r(e, t) {
    return e.write('<li class="ui-menu-item sayt-product-content" data-ui-autocomplete-item=\'{"title":"').reference(t.getPath(!1, ["allMeta", "title"]), t, "h").write('", "type":"product", "TheatreUrl":"').reference(t.getPath(!1, ["allMeta", "FilmUrl"]), t, "h").write('", "ProductUrlName":"').reference(t.getPath(!1, ["allMeta", "ProductUrlName"]), t, "h").write('", "StoreUrlId":"').reference(t.getPath(!1, ["allMeta", "StoreUrlId"]), t, "h").write('", "TheatreUrlName":"').reference(t.getPath(!1, ["allMeta", "TheatreUrlName"]), t, "h").write('"}\'><a class="sayt-product-anchor">').section(t.getPath(!1, ["allMeta", "ThumbnailUrl"]), t, {
      "else": a,
      block: l
    }, null).write('<div class="sayt-title-wrap"><span class="sayt-content sayt-product-title">').reference(t.getPath(!1, ["allMeta", "title"]), t, "h", ["s"]).write("</span></div>").section(t.getPath(!1, ["allMeta", "Directors"]), t, {
      block: i
    }, null).write('<div style="clear:both"> </div></a>	</li>');
  }
  function a(e, t) {
    return e.write('<img class="sayt-product-image" src="http://cineplex.cpxstoreimages.com/cineplex?set=imageSize[small50],imageURL[').reference(t.get(["defaultImage"], !1), t, "h").write(']&call=url[file:web/sizing]">');
  }
  function l(e, t) {
    return e.write('<img class="sayt-product-image" src="http://cineplex.cpxstoreimages.com/cineplex?set=imageSize[small50],imageURL[').reference(t.getPath(!1, ["allMeta", "ThumbnailUrl"]), t, "h").write(']&call=url[file:web/sizing]">');
  }
  function i(e, t) {
    return e.write('<div class="sayt-title-wrap"><div class="sayt-director-name">').reference(t.get(["PersonName"], !1), t, "h").write("</div></div>");
  }
  return dust.register("productTemplate", e), e;
}();